import React, { useContext, useEffect, useState } from "react";
import { onSnapshot } from "firebase/firestore";
import { vendorCollection } from "../../Config/Collections";
import { AuthContext } from "../../Config/Context";
import { SearchInput } from "../../Components/Input/SearchInput";
import { NewVendor } from "./NewVendor";
import { ExportExcel } from "../../Components/ExportExcel";
import { PermissionsGate } from "../../Routes/PermissionsAccess";
import { Permission_type } from "../../Config/Permission";
import { BiEditAlt } from "react-icons/bi";
import { deleteDocument } from "../../Middleware/FirebaseFunction";
import { toast } from "react-toastify";
import {buttonVariants} from "../../Components/ui/button"
import {manageVendorStore} from "./store";
import {VendorRequest} from "./components/VendorRequest";
import  Button from "../../Components/Button/Button"
export const Vendors = () => {
  const { setLoading,vendors } = useContext(AuthContext);
 const {setVendorRegistrationModalOpen,vendorRegistrationModalOpen}= manageVendorStore();
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [vendor, setVendor] = useState({});
console.log(vendorRegistrationModalOpen)
  return (
    <>
      <div>
        <div className="Header_wrapper">
          <div className="flex">
            <div className="search_container">
              <SearchInput
                placeholder="Search For Vendor"
                value={search}
                onchange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div>
          <Button
                style="button"
                color="c70b44d"
                title="New Vendor"
                onclick={() => {
                  setVendor({});
                  setIsOpen(true);
                }}
              />
            {/* <PermissionsGate permission={Permission_type.add_vendor}> */}
        
            {/* </PermissionsGate> */}

            <Button
              style="button"
              color="ff1d43"
              title="Export"
              onclick={() => ExportExcel(vendors)}
            />
            <buttonVariants onClick={()=>setVendorRegistrationModalOpen(true)} className="bg-transparent text-green-600 border border-green-600 px-2 h-8 hover:opacity-80 cursor-pointer">Pending Request</buttonVariants>
          </div>
        </div>

        <div className="payments_conatiner">
          <div className="table_container">
            <div className="thead">
              <span className="small_width">#</span>
              <span>Name</span>
              <span className="small_width">Phone</span>
              <span className="small_width">Location</span>
              <span className="small_width">In Price</span>
              <span className="small_width">Out Price</span>
              <span className="small_width"></span>
              {/* <span className="small_width"></span> */}
            </div>
            {vendors.length != 0 ? (
              vendors
                .filter((e) =>
                  e.value?.toLowerCase().includes(search.toLowerCase() || "")
                )
                .map((e, index) => (
                  <div className="tbody" key={index + 1}>
                    <span className="small_width">{e.id}</span>
                    <span>{e.value}</span>
                    <span className="small_width">{e.phone}</span>
                    <span className="small_width">{e.address}</span>
                    <span className="small_width">{e.in_price}</span>
                    <span className="small_width">{e.out_price}</span>

                    <span className="small_width">
                      <PermissionsGate permission={Permission_type.add_vendor}>
                        <BiEditAlt
                          size={"20px"}
                          onClick={() => {
                            setVendor(e);
                            setIsOpen(true);
                          }}
                        />
                      </PermissionsGate>
                    </span>

                    {/* <span
                      className="small_width"
                      onClick={() => deleteVendor(e.id)}
                    >
                      <PermissionsGate permission={Permission_type.add_vendor}>
                        Delete
                      </PermissionsGate>
                    </span> */}
                  </div>
                ))
            ) : (
              <div>No Vendors</div>
            )}
          </div>
        </div>
        {isOpen && (
          <NewVendor isOpen={isOpen} setIsOpen={setIsOpen} vendor={vendor} vendors={vendors}/>
        )}
      </div>
      {
        vendorRegistrationModalOpen &&      <VendorRequest/>

      }
    </>
  );
};
{
}
