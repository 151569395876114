import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { getRangeOrderByDate } from "../../Middleware/FirebaseFunction";
import { Order_status } from "../../Assets/DataSchema/OrderStatus";
import { CurrencyIqFormat } from "../../Components/Input/CurrencyIqFormat";
import { ExportButton } from "../../Components/Button/ExportButton";
import { BsFileEarmarkExcel } from "react-icons/bs";
import { ExportExcel } from "../../Components/ExportExcel";
import dayjs from "dayjs";
import { AuthContext } from "../../Config/Context";
import { cityMapper } from "../../Services/CityMapping";
const Home = () => {
  const [orders, setOrders] = useState([]);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const {vendors}=useContext(AuthContext)

  const fetchCODByDate = async () => {
    if (!startDate || !endDate) return;
    const response = await getRangeOrderByDate(
      Order_status.cod,
      startDate,
      endDate
    );
    setOrders(response);
    console.log(response);
  };
  useEffect(() => {
    fetchCODByDate();
  }, [startDate, endDate]);
  const getVendorOrderShipping=(order)=>{
var vendor=vendors.find(e=>e.value==order.vendor);
if(!vendor) return 0;
if([vendor.city.toLowerCase()].includes(cityMapper[order.city]?.toLowerCase()||order.city?.toLowerCase())) return vendor.in_price;
return vendor.out_price;
  }
  const exportData = () => {
    const data = orders.map((item) => {
      return {
        ...item,
        extraFee:item.job_description?.includes(true)
        ? parseInt(item.job_description.split("-")[1])
        :0,
        shipping:
         parseInt(
          getVendorOrderShipping(item)||0
          ),
        createAt: dayjs(new Date(item.createAt.seconds * 1000)).format(
          "YYYY-MM-DD"
        ),
      };
    });
    ExportExcel(data);
  };
  return (
    <>
      <div className="Header_wrapper">
        <div className="flex">
          <p>Tamata Line</p>
        </div>
      </div>
      <div
        style={{
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "300px",
          }}
        >
          <div className="card_title_price">Total COD</div>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            withPortal
            className="date_pick"
            placeholderText="From - To"
            isClearable={true}
          />
        </div>
        <div className="card_details_price">
          <span>{orders.length || 0} Items</span>
          <BsFileEarmarkExcel
            onClick={() => exportData()}
            fill="green"
            size={"30px"}
            style={{ cursor: "pointer", margin: "3px" }}
          />
          <div>
            <span className="currency">
              <CurrencyIqFormat
                val={orders.reduce((a, b) => a + b.agent_received || 0, 0)}
              />
            </span>
            <span>IQD</span>
          </div>
        </div>
      </div>{" "}
    </>
  );
};
export default Home;
