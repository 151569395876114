import React, {useCallback, useContext, useEffect, useState} from "react";
import Select from "react-select";
import {FetchCodTask} from "../../Middleware/FetchVendorTask";
import {AuthContext} from "../../Config/Context";
import {
  getAllDoc,
  getMultiDoc,
  getQustomQuery,
  setDocumnet,
  updateMultiDoc,
} from "../../Middleware/FirebaseFunction";
import {
  PaymentsCollections,
  vendorCollection,
  ordersCollections,
  vendorCodCollection,
} from "../../Config/Collections";
import {CurrencyIqFormat} from "../../Components/Input/CurrencyIqFormat";
import {IoIosAlert} from "react-icons/io";
import {BiEditAlt} from "react-icons/bi";
import {MdDeleteForever} from "react-icons/md";
import dayjs from "dayjs";
import {CurrencyInput} from "../../Components/Input/CurrencyInput";
import {custom_style_vendor} from "../../Assets/styles/custom_select";
import {totalPaidPrice} from "../../Middleware/OrdersCal";
import Button from "../../Components/Button/Button";
import {toast} from "react-toastify";
import {PermissionsGate} from "../../Routes/PermissionsAccess";
import {Permission_type} from "../../Config/Permission";

import {ReadExcel} from "../../Components/ReadExcel";
import {ExportCSV} from "../../Components/ExportCSV";
import {increment, serverTimestamp} from "firebase/firestore";
import DatePicker from "react-datepicker";
import {Order_status} from "../../Assets/DataSchema/OrderStatus";
import {cityMapper} from "../../Services/CityMapping";
import {generateRandomNumberString} from "../../Helper/tools";

export const orderDateType=[{
  value:"createAt",
  label:"createAt"
},
{
  value:"deliveredAt",
  label:"deliveredAt"
}]
const Accounting = () => {
  const [vendorOrders, setVendorOrders] = useState([]);
  const [vendor, setVendor] = useState(null);
  const [vendorPrice, setVendorPrice] = useState(null);
  const [vendorSelects, setVendorSelects] = useState([]);
  const {setLoading, currentUser, vendors} = useContext(AuthContext);
  const [dateRange, setDateRange] = useState([null, null]);
  const [requireAmount, setRequireAmount] = useState(0);
  const [filterByRequireAmount, setFilterByRequireAmount] = useState(false);
  const [filterDateType, setFilterDateType] = useState(orderDateType[0]);
  const [startDate, endDate] = dateRange;
  console.log(filterDateType)
  const getTask = useCallback(async () => {
    if (!vendor||!endDate||!startDate) return;
    setLoading(true);
  
    let data = await FetchCodTask(vendor.value, startDate, endDate,filterDateType.value);
    if (!data) {
      setLoading(false);
      return;
    }
    console.log(vendor);

    data = data
      .filter((e) => e.barcode)
      .map((e, index) => {
        return {
          ...e,
          isFree: index < vendor?.free_order,
          shipping:
            index < vendor?.free_order
              ? 0
              : parseInt(
                vendorPrice[
                [vendor?.city.toLowerCase()].includes(
                  cityMapper[e.city]?.toLowerCase() || e.city?.toLowerCase()
                )
                  ? "in_price"
                  : "out_price"
                ]
              ) + e.specialPrice,
        };
      });
    console.log(data);
    setVendorOrders(data);
    setLoading(false);
  }, [vendor,startDate,endDate]);

  useEffect(() => {
    getTask();
  }, [getTask]);

  const fetchVendors = async () => {
    var AccountingVendors = [];
    var codVendors = await getQustomQuery(vendorCodCollection, "value", ">", 0);
    codVendors = codVendors.map((e) => e.id);
    AccountingVendors = vendors;
    setVendorSelects([
      ...AccountingVendors.filter((e) => codVendors.includes(e.value)),
    ]);
  };
  useEffect(() => {
    fetchVendors();
  }, []);
  const filterOrdersByTotalAmount = (status) => {
    let sum = 0;
    console.log(status)
    setVendorOrders(prevState=>{
      prevState = prevState.map(order => {
        sum += order.agent_received;
        if (sum <= requireAmount) {
          return {
            ...order,
            isSelected: status
          }
        };
        return order
      });
      sum=0;
      return [...prevState]
    })
  
  };

  var data =  vendorOrders.filter((e) => e.isSelected);

  const groupOrdersByMonth = (data) => {
    const months = data.map((e) =>
      dayjs(new Date(e.createAt.seconds * 1000)).format("MM")
    );
    return [...new Set(months)];
  };
  const saveInvoice = async () => {
    if (data.length === 0 ) {
      toast.error("Save all orders");
      return;
    }
    console.log(data);
    try {
      setLoading(true);
      let id = `${vendor.value}-${dayjs(new Date()).format(
        "YYMMDhmss"
      )}-${Math.floor(10 + Math.random() * 99)}`;

      let result = await setDocumnet(PaymentsCollections, id, {
        vendor: {
          address: vendorPrice.address,
          city: vendorPrice.city,
          value: vendorPrice.value,
          phone: vendorPrice.phone,
          label:vendorPrice.label,
        },
        name: vendorPrice.value,
        paymentId: id,
        ordersMonth:[...groupOrdersByMonth(data)||""],
        paymentVerificationCode:generateRandomNumberString(8),
        orders: data.map((e) => ({
          agent_received: e.agent_received,
          barcode: e.barcode,
          city: e.city,
          shipping: e.shipping,
          amount: e.amount,
          specialPrice: e.specialPrice,
          createAt: e.createAt,
          deliveredAt:e.deliveredAt||""
        })),
        createAt: dayjs(new Date()).format("YYYY-MM-DD hh:mm:ss"),
        createBy: currentUser?.name,
        paid: false,
      });

      if (result) {
        console.log(1);
        const unresolved = data.map(async (e, index) => {
          await updateMultiDoc(ordersCollections, e.id, {
            paymentId:id,
            status: Order_status.invoice,
            paidValue: e.agent_received,
            shippingFee:e.shipping,
            paidAt: serverTimestamp(),
          });
        });
        const resolved = await Promise.all(unresolved);

        setVendorSelects((prev) => {
          prev = prev.filter((e) => e.id !== vendor?.id);
          return [...prev];
        });
        await updateMultiDoc(vendorCodCollection, vendor.value, {
          value: increment(-data.length),
        });

        if (vendor.free_order > 0) {
          await updateMultiDoc(vendorCollection, vendor.id, {
            free_order: increment(-data.filter((e) => e.isFree).length),
          });
        }
        setVendor(null);
        setVendorOrders([]);
        toast.success("Invoice Create Successful");
        console.log(2);
        setLoading(false);
      } else {
        console.log(3);

        setLoading(false);
        toast.error("حدث خطا");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("حدث خطا اثناء انشاء الفاتورة");
    }
  };


  const orderAlert = (order) =>
    order.agent_received < 1000 ||
    order.agent_received > 1000000 ||
    order.agent_received !== order.amount ||
    order.agent_received !== order.amount;
  const selectRow = (index) => {
    setVendorOrders((prevState) => {
      prevState[index].isSelected = !prevState[index].isSelected;

      return [...prevState];
    });
  };
  const selectAllRow = (status) => {
    console.log(status);
    setVendorOrders((prevState) => {
      prevState = prevState.map((e) => ({...e, isSelected: status}));
      return prevState;
    });
  };

  const uploadCsv = async (e) => {
    e.preventDefault();
    ReadExcel(e, async (file) => {
      if (!file) return;
      setVendorOrders((prevState) => {
        for (let order of file) {
          if (order.barcode) {
            const orderIndex = prevState.findIndex(
              (e) => e.id == order.barcode.toString()
            );
            if (orderIndex) {
              prevState[orderIndex].isSelected = true;
            }
          }
        }
        console.log(prevState);
        return [...prevState];
      });
    });
  };
  console.log(requireAmount)
const exportOrders=()=>{
  var data=vendorOrders.map(e=>({
    ...e,
    createAt:dayjs(new Date(e.createAt.seconds * 1000)).format(
      "YYYY-MM-DD"
    )
  }));
  ExportCSV(data)
}
  return (
    <>
      <div
        className="Header_wrapper_vendor_accounting"
        style={{
          justifyContent: "space-around",
          gap: 16,
        }}
      >
        <p>Invoice</p>
        <div className="select_style">
          <Select
            options={vendorSelects}
            name="colors"
            value={vendor}
            onChange={(val) => {
              setVendorOrders([]);
              setVendorPrice(val);
              setVendor(val);
            }}
            isClearable={true}
            placeholder="Vendor Name"
            styles={custom_style_vendor()}
          />
        </div>

        <div className="select_style">
          <Select
            options={orderDateType}
            name="colors"
            value={filterDateType}
            onChange={(val) => {
            setFilterDateType(val)
            }}
            defaultValue={filterDateType}
            isClearable={true}
            placeholder="Date Type"
            styles={custom_style_vendor()}
          />
        </div>
        <span style={{fontSize: "15px", marginLeft: "20px"}}>

          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            withPortal
            className="date_pick"
            placeholderText="From - To"
            isClearable={true}
            />
        </span>
        
  


        <div className="vendor_price">
          <input
            onChange={(e) => uploadCsv(e)}
            accept=".xlsx"
            id="file"
            type="file"
          ></input>
          <p> Vendor : </p>
          <span style={{color: "red", fontWeight: "600"}}>
            {vendorSelects.length}
          </span>
        </div>
        <p className="Export_green" onClick={() => exportOrders()}>
          Export Orders
        </p>

        {vendor?.free_order > 0 && (
          <div className="vendor_price">
            <p style={{color: "red", fontWeight: "600"}}>
              Free Order :{vendor?.free_order || 0}{" "}
            </p>
          </div>
        )}

        <div style={{display: "flex", alignItems: "flex-start"}}>
          {data.length !== 0 && (
            <>
              <PermissionsGate permission={Permission_type.create_invoice}>
                <Button
                  style="button"
                  color="c70b44d"
                  title="Generate Invoice"
                  onclick={() => saveInvoice()}
                />
              </PermissionsGate>
            </>
          )}
        </div>
      </div>
      <div className="container">
        <div
          className="table_container"
          style={{height: "80vh", overflow: "auto"}}
        >
          <div className="thead">
            <span>
              <input
                style={{
                  width: "20px",
                  height: "20px",
                }}
                type="checkbox"
                onChange={(e) => selectAllRow(e.target.checked)}
              />
            </span>
            <span>#</span>
            <span>Order</span>
            <span>ِAmount</span>
            <span>Shipping</span>
            <span>City</span>
            <span>Paid Amount</span>
            <span>Total Amount</span>

            <span className="small_width">Delivered At</span>
            <span className="small_width">Entry Date</span>
            <span className="small_width"></span>
            <span className="small_width"></span>
          </div>
          {vendorOrders?.map((e, index) => (
            <div className="tbody" key={index}>
              <span onClick={() => selectRow(index)}>
                <input
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                  type="checkbox"
                  value={e.isSelected}
                  checked={e.isSelected}
                />
              </span>
              <span>{index + 1}</span>
              <span>{e.id}</span>
              <span>
                <CurrencyInput
                  value={e.amount}
                  style={`${ "default_currency"}`}
                  // disabled={e.isEdit ? false : true}
                  onchange={(val) => {
                    setVendorOrders((prevState) => {
                      const OrderIndex = prevState.findIndex(
                        (od) => od.id == e.id
                      );

                      prevState[OrderIndex].amount = val.floatValue;
                      return [...prevState];
                    });
                  }}
                />
              </span>
              <span>
                {
                  <CurrencyInput
                  
                    style={`${e.isEdit ? "Number_Format" : "default_currency"}`}
                    disabled={e.isEdit ? false : true}
                    onchange={(val) => {
                      setVendorOrders((prevState) => {
                        const OrderIndex = prevState.findIndex(
                          (od) => od.id == e.id
                        );
console.log(OrderIndex)
                        prevState[OrderIndex].shipping = val.floatValue;
                        return [...prevState];
                      });
                    }}
                    value={e.shipping}
                  />
                }
              </span>
              <span>{e.city?.length > 20 ? "" : e.city}</span>
              <span>
                <CurrencyInput
                  style={`${ "default_currency"}`}
                  value={e.agent_received}
                  // disabled={e.isEdit ? false : true}
                  onchange={(val) => {
                    setVendorOrders((prevState) => {
                      const OrderIndex = prevState.findIndex(
                        (od) => od.id == e.id
                      );

                      prevState[OrderIndex].agent_received = val.floatValue;
                      return [...prevState];
                    });
                  }}
                />
              </span>
              <span>
                <CurrencyIqFormat val={e.agent_received - e.shipping} />
              </span>
              <span className="small_width">
                {e.deliveredAt}

              </span>
              <span className="small_width">
                {dayjs(new Date(e.createAt.seconds * 1000)).format(
                  "YYYY-MM-DD"
                )}
                <span className="small_width alert">
                  {orderAlert(e) && <IoIosAlert />}
                </span>
              </span>
              {/* <PermissionsGate permission={Permission_type.edit_price}> */}
              <span className="small_width delete_icon "
              onClick={() => {
                  setVendorOrders((prevState) => {
                    const OrderIndex = prevState.findIndex(
                      (od) => od.id == e.id
                    );
                    if (
                      prevState[OrderIndex].agent_received < 0 ||
                      prevState[OrderIndex].amount < 0 ||
                      prevState[OrderIndex].shipping < 0
                    ) {
                      return [...prevState];
                    }
                    prevState[OrderIndex].isEdit =
                      !prevState[OrderIndex].isEdit;
                    return [...prevState];
                  });
                }}>
                                  <BiEditAlt size={"20px"} />

                <span className="edit_icon">{e.isSpecial && "Big Order"}</span>
              </span>
              {/* </PermissionsGate> */}
              <span className="small_width">
                <MdDeleteForever
                  size={"20px"}
                  onClick={() =>
                    setVendorOrders((prevState) => [
                      ...prevState.filter((r) => r.id != e.id),
                    ])
                  }
                />
              </span>
            </div>
          ))}
        </div>
        {vendor && (
          <>
            <div className="total_details">
              <div>
                <p>Total Paid Amount : </p>
                <CurrencyIqFormat val={totalPaidPrice(data)} />
              </div>
              <div>
                <p>Total Shipping : </p>
                <CurrencyIqFormat
                  val={data.reduce((a, b) => a + parseInt(b.shipping), 0)}
                />
              </div>
              <div>
                <p>Total Vendor Received : </p>
                <CurrencyIqFormat
                  val={
                    totalPaidPrice(data) -
                    data.reduce((a, b) => a + parseInt(b.shipping), 0)
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Accounting;
